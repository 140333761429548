import { useState, useEffect, useContext, useRef } from "react";
import { KnotContext } from "./KnotProvider";
import {takenKnot, addedNote, bookMarkedKnot} from './events'




export default function Knot (props) {
    const {setUsersKnotData} = useContext(KnotContext); 
    const {name, uiid, number, description, taken, bookmarked, note, mark=null , knotType=null} = props.details; 
    const {onTakenConfetti} = props; 
    const [expanded, setExpanded] = useState(false);
    const [knotNote , setKnotNote] = useState(''); 

    const expandToggle = () => {
      setExpanded(previus => !previus)
    }

    const updateKnotUserData = (obj) => {

      setUsersKnotData((previousUserKnotData) => {
        
        const index = previousUserKnotData.findIndex((knot) => knot.uiid === uiid)
        
        if (index === -1) {
          return [...previousUserKnotData , {uiid, ...obj}]
        } else {
          const previousObject = previousUserKnotData[index]
          const updatedObject = {...previousObject,  ...obj}
          const newArray = [...previousUserKnotData]
          newArray.splice(index, 1, updatedObject )
          return newArray
        }
      
      })

    }

    const handleBookmarkedClick = () => {
      if (bookmarked === 1) {
        updateKnotUserData({bookmarked:0})
      } else {
        updateKnotUserData({bookmarked:1})
      }
      bookMarkedKnot()
    }

    const handleTakenClick = () => {
      if (taken === 1) {
        updateKnotUserData({taken:0})
      } else {
        updateKnotUserData({taken:1})
        onTakenConfetti()
      }
      takenKnot()
    }

    const handleNoteUpdate = (note) => {
      updateKnotUserData({note})
      addedNote()
      setExpanded(false)
    }

    const showNote = () => {
      const originalNote = note
      const noteText = prompt('Legg til et privat notat', originalNote)

      if (noteText !== originalNote ) {
         if (!noteText) {
          handleNoteUpdate('')
         } else {
          handleNoteUpdate(noteText)
         }
      }
    }

    useEffect(() => {
      setKnotNote(note); 
    }, [])

    return (
        <div className="grid-knot">
          <button  onClick={handleTakenClick} className={`taken-button play-Ball-button ${(taken===1)?'taken-selected':''} selct-btn`}></button>
          <div className="knot-info">
              <p className="knot-name-headline"> <span className="parag">§</span> {number} - {name.trim().toUpperCase()}</p>
              <p className="knot-description-paragraph">{description}</p> 
              {mark && <p><strong>Merke:</strong> {mark.substring(0,1).toUpperCase() + mark.substring(1).toLowerCase()}</p>}
              {knotType && <p><strong>Knutetype:</strong>  {knotType}</p>}
              {<button className="button-new" onClick={expandToggle}>
                {(expanded) ? 'Lukk ↑' : 'Vis mer ↓'}
                { knotNote.length > 0 && <span className="circle"></span>}
                </button> }
          </div>
        <div className="bookmark flx">
              <div className="vl"></div>
              <button className={`icon ${(bookmarked===1)?'selected':''}`} onClick={()=> handleBookmarkedClick()}></button>
        </div>
       {expanded && <div className="knot-expanded">
          <p> <span className="bold">Privat notat:</span> (eks: vitner , når og hvor)</p>
          <textarea onChange={(e) => setKnotNote(e.currentTarget.value)} className="note-text-area" cols="30" value={knotNote} rows="10"></textarea>
          <button onClick={() => handleNoteUpdate(knotNote) } className="button-19">Lagre notat</button>
        </div>}
        </div>
  ); 

  
  {expanded && 
    <div>
      <p>Knute: Navn på knute</p>
      <p>Beskrivelse</p>
      <p>Privat notat</p>
      <textarea className="note-text-area" cols="30" rows="10"></textarea>
      <button className="button-19">Save and archive</button>
      {expanded &&  <button onClick={expandToggle}>⬆️ Lukk</button> }
    </div>
  }


    
    return (
        <div className="knot-card">
            <div className="flx1">
              <button  onClick={handleTakenClick} className={`play-Ball-button ${(taken===1)?'taken-selected':''} selct-btn`}></button>
              <div className="card-text">
                <p className="knot-name-headline"> <span Name="parag">§</span> {number} - {name} <button onClick={showNote} Name='note-button' >✏️</button>  </p>
                <p className="knot-description-paragraph">{description}</p>
              </div>
            </div>
            <div className="flx">
              <div className="vl"></div>
              <button className={`icon ${(bookmarked===1)?'selected':''}`} onClick={()=> handleBookmarkedClick()}></button>
            </div>
            <div>
            </div>
        </div>
    ); 





  
    
}