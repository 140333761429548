import './App.css';
import { KnotContext } from './KnotProvider'; 
import React , { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';


export default function Navbar () {
    const contex = useContext(KnotContext)
    
    const {setFilterPreferences, availableYearAndLocation, filterPreferences, sortingOrder, setSortingOrder, searchTerm} = contex; 
    
    const activeSearch = (searchTerm.length > 0) ? true : false


    return (
        <header>
            <h1 id='top-h1'>Russeknutene</h1>
            <div style={{display:'flex', justifyContent:'center',  gap:'8px' , marginBottom:'5px', paddingBottom:'5px'}}>
                <select name="År" disabled={activeSearch} value={filterPreferences.year} className="inline-sel" onChange={(e)=> setFilterPreferences({location: availableYearAndLocation[e.target.value][0] ,  sorting:'Numerical' ,  year: e.target.value})}>
                    {Object.keys(availableYearAndLocation).map((year, index) => <option key={index} value={year}>{year}</option> )}
                </select>
                <select name="Sted" disabled={activeSearch} className='inline-sel' value={filterPreferences.location} onChange={(e)=> setFilterPreferences((previous) => ({...previous , sorting:'Numerical', location: e.target.value}))}>
                    {availableYearAndLocation[filterPreferences['year']].map(city => <option value={city} key={city}>{city}</option> )}
                </select>
                <select name="Sorting" disabled={activeSearch} value={sortingOrder} className="inline-sel" onChange={(e)=> setSortingOrder(e.target.value)}>
                    <option value="Numerical">Numerisk</option>
                    <option value="Taken">Tatt</option>
                    <option value="Bookmarked">Bookmarked</option>
                </select> 
            </div>
        </header>
    ); 


    return (
        <header>
        <h1 id='top-h1'>Russeknutene</h1>
        <p id='selectNav'>År:&nbsp; 
        <select name="År" disabled={activeSearch} value={filterPreferences.year} className="inline-sel" onChange={(e)=> setFilterPreferences({location: availableYearAndLocation[e.target.value][0] ,  sorting:'Numerical' ,  year: e.target.value})}>
            {Object.keys(availableYearAndLocation).map((year, index) => <option key={index} value={year}>{year}</option> )}
        </select>
        &nbsp;Sted:&nbsp; 
        <select name="Sted" disabled={activeSearch} className='inline-sel' value={filterPreferences.location} onChange={(e)=> setFilterPreferences((previous) => ({...previous , sorting:'Numerical', location: e.target.value}))}>
            {availableYearAndLocation[filterPreferences['year']].map(city => <option value={city} key={city}>{city}</option> )}
        </select>
        <span className='sorter'>&nbsp;Sorter:&nbsp;
        <select name="Sorting" disabled={activeSearch} value={sortingOrder} className="inline-sel" onChange={(e)=> setSortingOrder(e.target.value)}>
            <option value="Numerical">Numerisk</option>
            <option value="Taken">Tatt</option>
            <option value="Bookmarked">Bookmarked</option>
        </select> </span>
        </p>
        </header>
    ); 

}