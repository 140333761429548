import SettingsCard from "./SettingsCard";
import {Link} from 'react-router-dom'
import MailboxIcon from './static/Mailbox.png';
import MicrophoneIcon from './static/Microphone.png';
import { useState, useEffect } from "react";


export default  function SettingsContainer () {


    return (
        <div className="settings-container">
        <div className="flex">
            <Link to='/' className="settings-link back-stretch">⬅️ Tilbake</Link>
            <SettingsCard image={MailboxIcon}  title='Send inn knuter' paragraph='Representere du et russestyre eller ønsker du å varsle fra om noen knuter vi ikke har lagt inn. Det er forøvrig gratis å sende inn knuter' buttonText='Send inn' buttonLink='https://uqf9i11uspn.typeform.com/to/ah9ioD1f'/>
            <SettingsCard image={MicrophoneIcon} title='Feedback/forslag til funksjoner' paragraph='Har du feedback eller noen ønsker i form av nye funksjoner' buttonText='Gi feedback' buttonLink='https://uqf9i11uspn.typeform.com/to/Jesvt0wE'/>
        </div>
        </div>
    ); 
}