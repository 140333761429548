import { KnotContext } from "./KnotProvider"
import { useContext } from "react"


export default function SearchBar () {
    const context = useContext(KnotContext); 

    return (
        <div className="search-bar-container"> 
        <input type="search" placeholder="Søk etter knute" id='searchBar' onChange={(e)=> context.setSearchTerm(e.target.value)} />
        </div>
    )

   
}