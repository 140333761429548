export default function SettingsCard (props) {

    const {title, paragraph ,  buttonText,  buttonLink, image} = props


    return (
        <div className="settings-card">
            <img className="settings-emoji" src={image} height='47' width='47' alt="" />
            <div className="settings-flex">
                <p className="headline">{title}</p>
                <p className="settings-paragrap">{paragraph}</p>
                {buttonText.length > 0 && buttonLink.length > 0 && <a className="settings-link"  target='_blank' href={buttonLink}>{buttonText}</a> }
            </div>
        </div>
    ); 

}