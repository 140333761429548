import './App.css';
import Navbar from './Navbar';
import SearchBar from './SearchBar';
import KnotContainer from './KnotContainer';
import {KnotProvider} from './KnotProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import SettingsContainer from './SettingsContainer';
import Walkthrouhg from './Walkthrough';
import { useState, useEffect } from 'react';



function App() {

  /*
const [completedWalkthrouhg, setCompletedWalkthrouhg] = useState(()=> {
  if (localStorage.getItem('completed_walkthrouhg') === 'true') {
    return true
  } else {
    return false
  }
})
*/



  return (
    <div className="App">
  <BrowserRouter>
    <KnotProvider>
      <Navbar/>
        <Routes>
          <Route exact path="/*" element={<><SearchBar/>  <KnotContainer />  </>}/>
          <Route exact path="/instillinger" element={<SettingsContainer />}/>
        </Routes>
    </KnotProvider>
  </BrowserRouter>
    </div>
  );
}

export default App;
