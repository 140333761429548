import Knot from "./Knot";
import KnotNotFound from "./Knot-Non-Found";
import KnotTakenStats from "./Knot-taken-stats";
import { useState, useEffect, useContext, useRef } from "react";
import { KnotContext } from "./KnotProvider";
import Confetti from 'react-confetti'


// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }


export default function KnotContainer () {
  const context = useContext(KnotContext)
  const {sortingOrder} = context
  const numberOfTAkenKNots = context.knots.reduce((accum, current) => accum + current.taken , 0)

  const knotContainerRef = useRef()
  const [showConfetti, setShowConfetti] = useState(false); 

  const { width, height } = useWindowSize()

  const handleTakenConfettiShow = () => {
    setShowConfetti(true)
  }

  useEffect(() => {
      if(showConfetti) {
          const timerId = setTimeout(() => {
              setShowConfetti(false)
          }, (6000));
          return () => clearTimeout(timerId)
      }
  },[showConfetti]); 

    return (
        <>
        <div className="knot-status"> 
        {!context.searchTerm && context.matchingSearchKnots.length === 0 && <KnotTakenStats taken={numberOfTAkenKNots} total={context.knots.length} /> }
        </div>
        <div className="knot-container-box" ref={knotContainerRef} >          
          {context.searchTerm && context.matchingSearchKnots.length === 0 &&  <KnotNotFound/> }
          {context.searchTerm && context.matchingSearchKnots.length > 0  && context.matchingSearchKnots.map(knot => <Knot onTakenConfetti={handleTakenConfettiShow}  key={knot.uiid} details={knot} />) }
          {!context.searchTerm && context.knots.sort((a, b)=> (sortingOrder==='Taken')? b.taken - a.taken : (sortingOrder==='Bookmarked')? b.bookmarked - a.bookmarked : a.number - b.number).map(knot => <Knot onTakenConfetti={handleTakenConfettiShow}   key={knot.uiid} details={knot} />)}
          {showConfetti && <Confetti
            width={width}
            height={height}
            numberOfPieces={400}
            recycle={false}
          />} 
        </div>
        </>
    ); 
}

