import { KnotContext } from "./KnotProvider";
import { useContext } from "react";
import notfound from './static/notfound.png'


export default function KnotNotFound () {

  const context = useContext(KnotContext)

return (

    <div className="knot-card">
    <div className="flx1">
      <img style={{flexShrink:0 , flexGrow:0 , marginLeft:'10px'}}  src={notfound} alt="" width='40px' height='40px' />
      <div className="card-text">
        <p className="knot-name-headline"> Ingen treff</p>
        <p className="knot-description-paragraph">Vi fant dessverre ingen knuter som matchet '<span className="marked">{context.searchTerm}</span>' </p>
      </div>
    </div>
    <div>
    </div>
</div>

); 
    
}