import {Link} from 'react-router-dom'
import pointerIcon from './static/Pointer.png'

export default function KnotTakenStats (props) {


    return (
        <div className="knot-card">
        <div className="flx1">
          <img className="no-hits-emoji" style={{flexShrink:0 , flexGrow:0}}  src={pointerIcon} alt="" width='40px' height='40px' />
          <div className="taken-card-text ">
            <p  style={{margin: 0, padding:0}} className="knot-name-headline"><span className="marked">{`${props.taken}/${props.total}`}</span> Knuter</p>
          </div>
          <Link to="/instillinger" className="settings-gear">Instillinger</Link>
        </div>
        <div>
        </div>
    </div>
    
    ); 
        
    }
