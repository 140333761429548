import { createContext, useState, useEffect } from "react";
import { yearAndLocations } from "./Knot-Data/yearAndLocations";

export const KnotContext = createContext(); 


export function KnotProvider (props) {
    const [knots , setKnots] = useState([])
    const [searchTerm, setSearchTerm] = useState(''); 
    const [availableYearAndLocation] = useState(yearAndLocations); 
    const [matchingSearchKnots , setMatchingSearchKnots] = useState([]); 
    const [sortingOrder , setSortingOrder] = useState('Numerical')
    const [filterPreferences, setFilterPreferences] = useState(()=> {
        const data = localStorage.getItem('filter_preferences')
        if (data) {
         return JSON.parse(data)
        } else {
            const oldestYear = Object.keys(yearAndLocations).sort((a,b)=> b-a)[0]; 
            const firstLocation = yearAndLocations[oldestYear][0]
            return {year:oldestYear, location:firstLocation}
        }
    })
    const [usersKnotData, setUsersKnotData] = useState(() => {
        const data = localStorage.getItem('user_knot_data')
        if (data) {
         return JSON.parse(data)
        } else {
         return []
        }
    }); 



    // fetching knots 
    useEffect(()=> {

        const locationQuery = filterPreferences.location.replaceAll(' ','_')
        const fetchData = async () => {
            return import(`./Knot-Data/knuter-${filterPreferences.year}-${locationQuery}.json`)
        }

        setSortingOrder('Numerical') // imperative 

        fetchData().then(data => { 

            const personalizedKnots = data.default.map(knot =>  {
                const knot_user_data = usersKnotData.find(knotInUserD => knotInUserD.uiid === knot.uiid)
                const hasTaken = (knot_user_data?.taken === 1) ? 1 : 0; 
                const hasNote =  (knot_user_data?.note?.length > 0 ) ? knot_user_data.note  : '';
                const hasBookMarked =  (knot_user_data?.bookmarked === 1) ? 1 : 0; 

                return ({...knot, taken:hasTaken , bookmarked:hasBookMarked, note:hasNote})
            })
                

            setKnots(personalizedKnots) 
        }).catch(err=> console.log(err))

    }, [filterPreferences])


    // Searching 
    useEffect(()=> {
        // load matching knots  and sort   
        if (!searchTerm)  {
            setMatchingSearchKnots([]); 
            return
        }
        
        const lowerCaseSearchTerm = searchTerm.toLowerCase()

        const matching = knots.filter(knot => {
            if (knot.name.toLowerCase().includes(lowerCaseSearchTerm) || knot.description.toLowerCase().includes(lowerCaseSearchTerm) || knot.number.toString().includes(lowerCaseSearchTerm)) {
                return true
            }
            return false
        }).sort(((a, b) => a.number - b.number))

        setMatchingSearchKnots(matching)

    }, [searchTerm])


    // Filter prefernce 
    useEffect(()=> {
        if (filterPreferences.location && filterPreferences.year) {
            localStorage.setItem('filter_preferences', JSON.stringify(filterPreferences))
        }
    }, [filterPreferences])

 

    // UserKnot information
    useEffect(()=> {
        if (usersKnotData.length > 0) {
            localStorage.setItem('user_knot_data', JSON.stringify(usersKnotData))
        }
    }, [usersKnotData])

    useEffect(()=> {
        const personalizedKnots = knots.map(knot =>  {
            const knot_user_data = usersKnotData.find(knotInUserD => knotInUserD.uiid === knot.uiid)
            const hasTaken = (knot_user_data?.taken === 1) ? 1 : 0; 
            const hasNote =  (knot_user_data?.note?.length > 0 ) ? knot_user_data.note  : '';
            const hasBookMarked =  (knot_user_data?.bookmarked === 1) ? 1 : 0; 

            return ({...knot, taken:hasTaken , bookmarked:hasBookMarked, note:hasNote})
        })

        if (matchingSearchKnots.length > 0) {
            const personalizedMatchingKnots = matchingSearchKnots.map(knot =>  {
                const knot_user_data = usersKnotData.find(knotInUserD => knotInUserD.uiid === knot.uiid)
                const hasTaken = (knot_user_data?.taken === 1) ? 1 : 0; 
                const hasNote =  (knot_user_data?.note?.length > 0 ) ? knot_user_data.note  : '';
                const hasBookMarked =  (knot_user_data?.bookmarked === 1) ? 1 : 0; 
    
                return ({...knot, taken:hasTaken , bookmarked:hasBookMarked, note:hasNote})
            })
            setMatchingSearchKnots(personalizedMatchingKnots)
        }
            
        setKnots(personalizedKnots) 

    }, [usersKnotData])





    const value = {
        setSearchTerm, 
        searchTerm,
        knots, 
        matchingSearchKnots, 
        setFilterPreferences, 
        setUsersKnotData, 
        availableYearAndLocation, 
        filterPreferences, 
        sortingOrder, 
        setSortingOrder, 
    }




      
    return (
        <KnotContext.Provider value={value}>
            {props.children}
        </KnotContext.Provider>
    )
}


